import { useState, useEffect, useRef } from 'react';
import { useRenderContext } from '../../pages/home/context';
import ClientService from '../../../services/client';
import { trackEvent } from '../../utils/tracking';
import tracks from '../../utils/tracks';

const KEY_MODAL_COUNT = 'CP_COUNT';
const KEY_MODAL_LAST_VIEW = 'CP_LAST_VIEW';
const VIEW_MODAL_COUNT = 3;
const actionModal = 'do_follow_incentive';

const useCouponModal = ({ modal, isEditable, commonTrackData = {} }) => {
  if (isEditable || modal === null || modal?.active === false) {
    return { modalUpdate: { ...modal, active: false } };
  }
  const modalCountRef = useRef(0);
  const [modalUpdate, setModalUpdate] = useState({ ...modal, active: false });
  const {
    shopModel: { storefront },
    marketplaceInfo,
    apiBasePath,
    device,
    shopType,
  } = useRenderContext();

  const { buyerId, ownerId, siteId } = marketplaceInfo;
  const last20Chars = storefront?.id?.slice(-20) || '';
  const KEY_COUNT_BY_STOREFRONT = `${KEY_MODAL_COUNT}_${last20Chars}`;
  const KEY_MODAL_LAST_BY_STOREFRONT = `${KEY_MODAL_LAST_VIEW}_${last20Chars}`;
  const KEY_POST_LOGIN = `CP_Login_${ownerId}`;

  const clientService = new ClientService(apiBasePath);

  const setTodayMidnight = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  const initializeModal = () => {
    localStorage.setItem(KEY_COUNT_BY_STOREFRONT, 1);
    localStorage.setItem(KEY_MODAL_LAST_BY_STOREFRONT, setTodayMidnight());
  };

  const updateModalCount = (count) => {
    const newCount = parseInt(count, 10) + 1;
    modalCountRef.current = newCount;
    localStorage.setItem(KEY_COUNT_BY_STOREFRONT, newCount);
    localStorage.setItem(KEY_MODAL_LAST_BY_STOREFRONT, setTodayMidnight());
  };

  const setCurrentTry = (day) => {
    const currentDay = day === 0 ? '1' : modalCountRef?.current?.toString();
    return currentDay;
  };

  // control for modal view in client side
  useEffect(() => {
    try {
      const lastView = localStorage.getItem(KEY_MODAL_LAST_BY_STOREFRONT);
      const count = localStorage.getItem(KEY_COUNT_BY_STOREFRONT);
      const notShowModal = localStorage.getItem(KEY_POST_LOGIN);

      if (notShowModal) {
        localStorage.removeItem(KEY_POST_LOGIN);
        setModalUpdate((modalupdate) => ({ ...modalupdate, active: false }));
      } else if (!lastView || !count) {
        initializeModal();
        setModalUpdate(modalupdate => ({ ...modalupdate, active: true }));

        trackEvent({
          ...tracks.couponsModalOpen,
          melidata_event: {
            ...tracks.couponsModalOpen.melidata_event,
            event_data: {
              ...tracks.couponsModalOpen.melidata_event.event_data,
              type_coupon: 'not_apply',
              try_shows: '1',
            },
          },
        }, commonTrackData);
      } else {
        const today = new Date().setHours(0, 0, 0, 0);
        const isNextDay = today > new Date(lastView);
        if (isNextDay) {
          if (count < VIEW_MODAL_COUNT) {
            setModalUpdate((modalupdate) => ({ ...modalupdate, active: true }));
            updateModalCount(count);
            trackEvent({
              ...tracks.couponsModalOpen,
              melidata_event: {
                ...tracks.couponsModalOpen.melidata_event,
                event_data: {
                  ...tracks.couponsModalOpen.melidata_event.event_data,
                  type_coupon: 'not_apply',
                  try_shows: setCurrentTry(modalCountRef?.current) ?? '1',
                },
              },
            }, commonTrackData);
          }
        }
      }
    } catch {
      setModalUpdate((modalupdate) => ({ ...modalupdate, active: false }));
    }
  }, []);

  const onClickSecondary = () => {
    trackEvent({
      ...tracks.couponsModalDismiss,
      melidata_event: {
        ...tracks.couponsModalDismiss.melidata_event,
        event_data: {
          ...tracks.couponsModalDismiss.melidata_event.event_data,
          type_coupon: 'not_apply',
          try_shows: setCurrentTry(modalCountRef?.current) ?? '1',
        },
      },
    }, commonTrackData);
    setModalUpdate((modalupdate) => ({ ...modalupdate, active: false }));
  };

  const onClickPrimary = () => {
    clientService
      .post('/set-follower', {
        data: {
          ownerId,
          buyerId,
          entityId: storefront?.id,
          entityType: 'storefront',
          withAudience: true,
          action: actionModal,
          queryParam: {
            source: 'modal',
            device,
            shopType,
            ownerId,
            siteId,
          },
        },
      })
      .then(() => {
        trackEvent(tracks.couponsFollow, commonTrackData);
        const messageEvent = new MessageEvent('reload-coupons', {
          data: {
            followState: 'stop_follow',
          },
        });
        if (window?.dispatchEvent) {
          window.dispatchEvent(messageEvent);
        }
      })
      .catch(() => {});
    setModalUpdate((modalupdate) => ({ ...modalupdate, active: false }));
  };

  return {
    modalUpdate,
    modalActions: {
      onClickSecondary,
      onClickPrimary,
    },
  };
};

export default useCouponModal;
